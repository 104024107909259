<script>
import { mapStores, mapWritableState } from 'pinia'

import { useOrgStore, useOptionsStore, useAccountStore } from '@/stores'
// import liffapi from '@/liffapi.js'
import utils from '@/utils.js'

import { BarcodeDetectorPolyfill } from '@undecaf/barcode-detector-polyfill'

export default {
  name: 'ScanTag',

  components: {},

  props: {},

  emits: ['change', 'scan'],

  data: () => ({
    isDebug: import.meta.env.VITE_DEBUG == 'true',
    showUI: false,
    isMirrored: false,
    videoSrc: null,
    devices: [],
    selectedDevice: null,
    preview: null,
  }),

  computed: {
    ...mapWritableState(useOptionsStore, ['deviceOptions']),
    ...mapStores(useOrgStore, useAccountStore),

    ok() {
      return true
    },
  },

  watch: {
    selectedDevice(val) {
      this.updateStream(val)
      this.$emit('change', val)
    },
  },

  async mounted() {
    await this.reload()
    this.selectedDevice = this.deviceOptions?.selectedDevice
  },

  async created() {
    try {
      window['BarcodeDetector'].getSupportedFormats()
    } catch {
      window['BarcodeDetector'] = BarcodeDetectorPolyfill
    }

    this.barcodeDetector = new window.BarcodeDetector({
      formats: ['qr_code'],
    })

    // let formats = await window.BarcodeDetector.getSupportedFormats()
    // console.log(formats)
  },

  async beforeUnmount() {
    this.turnOff()
  },

  methods: {
    scan() {
      try {
        this.barcodeDetector
          .detect(this.$refs.preview)
          .then((barcodes) => {
            barcodes.forEach((barcode) => this.onDecode(barcode))
          })
          .catch((err) => {
            console.log(err)
          })
      } catch {
        console.log(`BarcodeDetector error: ${this.$refs.preview}`)
      }

      if (this.videoSrc) {
        setTimeout(this.scan, 2000)
      }
    },

    onPlay() {
      console.log('onPlay')
      this.showUI = true
      this.scan()
    },

    onDecode(barcode) {
      let code = barcode?.rawValue || barcode
      if (utils.isLiffHost(code)) {
        this.$emit('scan', code)
      }
    },

    async turnOff() {
      console.log('stopping')
      console.log(this.videoSrc)

      await this.stream?.getVideoTracks().forEach((track) => {
        track.stop()
      })

      if (this.videoSrc) {
        this.videoSrc = null
        setTimeout(this.scan, 2000)
      }
    },

    async updateStream(deviceId) {
      console.log(`updateStream(${deviceId})`)
      await this.turnOff()

      const constraints = {
        video: {
          deviceId: deviceId,
          facingMode: 'environment',
          width: { ideal: 1280 },
          height: { ideal: 720 },
        },
      }

      window.navigator.mediaDevices
        .getUserMedia(constraints)
        .then((mediaStream) => {
          if (mediaStream === undefined) {
            console.error('no media stream')
            return
          }
          this.stream = mediaStream
          this.videoSrc = mediaStream
        })
        .catch((error) => console.error(error))
    },

    reload() {
      return window.navigator.mediaDevices
        .enumerateDevices()
        .then((deviceInfos) => {
          this.devices = deviceInfos.filter((info) => {
            return info.kind == 'videoinput'
          })
          console.log(this.devices.map((d) => d.deviceId))
          this.selectedDevice =
            this.deviceOptions?.selectedDevice || this.devices[0].deviceId
        })
    },

    nextDevice() {
      let n = this.devices.findIndex((d) => d.deviceId == this.selectedDevice)
      let next = (n + 1) % this.devices.length
      this.selectedDevice = this.devices[next]?.deviceId
      console.log(this.selectedDevice)
      this.deviceOptions = Object.assign(this.deviceOptions, {
        selectedDevice: this.selectedDevice,
      })
    },
  },
}
</script>

<template>
  <section class="card has-text-centered scantag-modal">
    <slot></slot>

    <form class="scantag-overlay">
      <div v-if="showUI" class="scantag-btns" style="">
        <o-button
          variant="text"
          size="medium"
          icon-left="camera-flip"
          class="capture-btn"
          style="color: white"
          @click.prevent="nextDevice" />
        <o-button
          variant="text"
          size="medium"
          :icon-left="isMirrored ? 'camera-document' : 'camera-document-off'"
          class="capture-btn"
          style="color: white"
          @click.prevent="isMirrored = !isMirrored" />
      </div>
      <div
        v-else
        class="container"
        style="position: relative; width: 100%; height: 20rem">
        <o-loading :full-page="false" active></o-loading>
      </div>
    </form>

    <p>Scan your tag</p>

    <video
      id="preview"
      ref="preview"
      class="scantag-camera"
      :class="{ 'mirror-video': isMirrored }"
      :srcObject.prop="videoSrc"
      autoplay
      muted
      playsinline
      @play="onPlay" />
  </section>
</template>

<style scoped lang="scss">
.scantag-camera {
  width: 100%;
  height: 100%;
  background-color: white;
}

.scantag-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
}

.scantag-btns {
  position: absolute;
  display: flex;
  bottom: 0;
  background-color: rgb(0, 0, 0, 0.5);
  justify-content: space-between;
  width: 100%;
  z-index: 10;
}

.scantag-btns button {
  margin: auto 0 auto 0;
}

.mirror-video {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
</style>

<style lang="scss">
.scantag-modal {
  gap: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 20rem;
}
</style>
